<template>
	<!-- Overflows are visible -->
	<ZyroModal
		max-width="758px"
		height="auto"
		class="published-modal"
		:delay="600"
		@close-modal="userClosesModal"
	>
		<div
			class="published-modal__text-container"
			:class="{ 'published-modal__text-container--additional-padding': customDomain }"
		>
			<h2
				class="published-modal__title z-h4"
				:class="{ 'published-modal__title--bottom-spacing' : hasActivePlan }"
			>
				<slot name="title" />
			</h2>
			<p
				v-if="!hasActivePlan"
				class="published-modal__subtitle"
			>
				<slot name="subtitle" />
			</p>
			<div
				class="published-modal__site"
				:class="{ 'published-modal__site--pro': !hasActivePlan }"
			>
				<ZyroDomainInput
					:value="(hasActivePlan && customDomain) ? customDomain : domain.split('.')[0]"
					class="published-modal__domain-input"
					:hide-subdomain="hasActivePlan && !!customDomain"
					:is-focused="false"
					readonly
				/>
				<ZyroButton
					:theme="hasActivePlan ? 'primary' : 'outline'"
					size="sm"
					class="published-modal__button z-button-small"
					data-qa="builder-publishmodal-btn-viewyoursite"
					@click="openSite"
				>
					{{ $t('common.viewWebsite') }}
				</ZyroButton>
			</div>
		</div>
		<div
			v-if="hasActivePlan && !customDomain"
			class="published-modal__seperator"
		/>
		<PublishedModalUpdateSection />
	</ZyroModal>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapActions,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import { getLastEvaluation } from '@/api/NpsApi';
import ZyroDomainInput from '@/components/ui/ZyroDomainInput.vue';
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';

import PublishedModalUpdateSection from './PublishedModalUpdateSection.vue';

export default {
	components: {
		ZyroDomainInput,
		PublishedModalUpdateSection,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapState([
			'domain',
			'customDomain',
			'hasActivePlan',
		]),
		...mapGetters(['siteUrl']),
	},
	methods: {
		...mapActionsGui({ closeModal: CLOSE_MODAL }),
		...mapActions('nps', ['updateDaysPassed']),
		openSite() {
			EventLogApi.logEvent({ eventName: 'builder.published_website_modal.click_button_view_site' });
			window.open(this.siteUrl, '_blank');
			this.checkNpsModalDaysPassed();
			this.closeModal();
		},
		async userClosesModal() {
			EventLogApi.logEvent({ eventName: 'builder.published_website_modal.close' });
			this.checkNpsModalDaysPassed();
		},
		async checkNpsModalDaysPassed() {
			try {
				const { daysPassed } = await getLastEvaluation();

				this.updateDaysPassed({ daysPassed });
			} catch {
				// ?? need proper error handling here
			} finally {
				this.closeModal();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.published-modal {
	color: $dark;
	$this: &;

	::v-deep .modal__content {
		display: flex;
		flex-direction: column;
		padding: 0;
		overflow: hidden;
		border-bottom-right-radius: 20px;
		border-bottom-left-radius: 20px;
	}

	&__text-container {
		padding: 64px 38px 32px 38px;

		&--additional-padding {
			padding-bottom: 64px;
		}
	}

	&__title {
		text-align: center;

		&--bottom-spacing {
			margin-bottom: 64px;
		}
	}

	&__button {
		margin-left: 12px;
	}

	&__seperator {
		width: 80%;
		margin: 38px auto;
		border-top: 1px solid $grey-200;
	}

	&__subtitle {
		margin: 8px auto 64px;
		font-size: 13px;
		line-height: 1.54;
		color: $grey-800;
		text-align: center;
	}

	&__site {
		display: flex;
		justify-content: space-between;

		&--pro {
			align-items: center;
			padding-bottom: 0;
		}

		&:not(:last-child) {
			border-bottom: 1px solid $grey-200;
		}
	}
}

@include zyro-media($media-tablet) {
	.published-modal {
		::v-deep .modal__content {
			display: flex;
			flex-direction: column;
			padding: 0;
		}

		&__button {
			margin: 24px auto 0;
		}

		&__text-container {
			padding: 56px 24px 0;
		}

		&__seperator {
			display: none;
		}

		&__site {
			display: flex;
			flex-direction: column;
			padding-bottom: 40px;
		}

		&__subtitle {
			margin-bottom: 40px;
		}
	}
}
</style>
