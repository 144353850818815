<template>
	<div
		v-if="hasActivePlan && !customDomain"
		class="premium"
	>
		<div class="premium__image-block">
			<img
				class="premium__image"
				src="@/assets/images/domain.png"
				:alt="$t('builder.publishedModalRoot.img.alt')"
			>
		</div>
		<div class="premium__text-container">
			<p class="premium__text z-body-small premium__text--bold">
				{{ $t('builder.publishedModalRoot.premium.title') }}
			</p>
			<p class="premium__text z-body-small">
				{{ $t('builder.publishedModalRoot.premium.text') }}
			</p>
			<ZyroButton
				theme="outline"
				size="sm"
				class="z-button-small"
				@click="connectDomain"
			>
				{{ $t('builder.publishedModalRoot.premium.btn') }}
			</ZyroButton>
		</div>
	</div>
	<div
		v-else-if="!hasActivePlan"
		class="upgrade"
	>
		<div class="upgrade__text-block">
			<p class="upgrade__offer z-title">
				{{ $t('builder.publishedModalRoot.label.title') }}
			</p>
			<p class="upgrade__discount z-h4 z-font-weight-bold">
				{{ $t('builder.publishedModalRoot.label.discount') }}
			</p>
			<p class="upgrade__plans z-button-small">
				{{ $t('builder.publishedModalRoot.label.text') }}
			</p>
		</div>
		<div class="upgrade__text-container">
			<p class="upgrade__text z-body-small upgrade__text--bold">
				{{ $t('builder.publishedModalRoot.steps.title') }}
			</p>
			<p class="upgrade__text z-body-small">
				{{ $t('builder.publishedModalRoot.steps.text') }}
			</p>
			<ul class="upgrade__list">
				<li
					v-for="(item, key) in $t('builder.publishedModalRoot.steps.list')"
					:key="key"
					class="upgrade__list-item z-body-small"
				>
					<p class="upgrade__list-text">
						<ZyroSvg
							class="upgrade__icon"
							name="check-mark"
						/>
						{{ item.text }}
					</p>
				</li>
			</ul>
			<ZyroButton
				v-qa="'builder-publishmodal-btn-allplans'"
				class="upgrade__list-button"
				theme="primary"
				color="plump-purple"
				@click="redirectToPayments"
			>
				{{ $t('builder.publishedModalRoot.btn') }}
			</ZyroButton>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import { DOMAIN_SETTINGS_ROUTE } from '@/router';
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import { useRedirects } from '@/use/useRedirects';

export default {
	setup() {
		const { redirectToWWWPayments } = useRedirects();

		return { redirectToWWWPayments };
	},
	computed: mapState([
		'hasActivePlan',
		'customDomain',
	]),
	methods: {
		...mapActionsGui({ closeModal: CLOSE_MODAL }),
		connectDomain() {
			this.$router.push({ name: DOMAIN_SETTINGS_ROUTE });
			this.closeModal();
		},
		redirectToPayments() {
			EventLogApi.logEvent({ eventName: 'builder.published_website_modal.click_button_see_all_plans' });
			this.redirectToWWWPayments({ discount: true });
		},
	},
};
</script>

<style lang="scss" scoped>
.upgrade {
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding: 32px 64px 40px 64px;
	overflow: hidden;
	background: $grey-100;

	&__text-container {
		margin-left: 56px;
	}

	&__offer {
		text-align: center;
	}

	&__list-item {
		display: flex;
		list-style-type: none;
	}

	&__icon {
		margin-right: 8px;
	}

	&__list-button {
		margin-top: 16px;
	}

	&__discount {
		padding: 2px 8px;
		margin-top: 24px;
		margin-bottom: 24px;
		color: $light;
		text-align: center;
		background-color: $secondary;
		border-radius: $border-radius-tiny;
	}

	&__plans {
		text-align: center;
	}

	&__text-block {
		max-width: 290px;
		padding: 48px 64px;
		background: rgba(255, 255, 255, 0.5);
		border: 1px solid $grey-200;
		border-radius: 10px;
	}
}

.upgrade,
.premium {
	&__text {
		margin-bottom: 16px;

		&--bold {
			margin-bottom: 8px;
			font-weight: bold;
		}
	}
}

.premium {
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding: 32px 64px 64px 64px;

	&__image {
		max-width: 290px;
	}

	&__text {
		color: $grey-800;
	}

	&__image-block {
		margin-right: 32px;
	}

	&__text-container {
		margin-left: 32px;
	}
}

@include zyro-media($media-tablet) {
	.upgrade {
		display: flex;
		flex-direction: column;
		padding: 40px 0 48px;
		margin: 0;
		background: none;
		border-top: 1px solid $grey-200;

		&__text-container {
			align-self: center;
			margin-left: 0;
			text-align: center;
		}

		&__text-block {
			margin: 0 auto;
			margin-bottom: 40px;
			object-fit: contain;
		}

		&__list {
			max-width: 194px;
			margin: 0 auto;
		}

		&__list-item {
			margin: 0 auto;
		}
	}

	.premium {
		display: none;
	}
}
</style>
